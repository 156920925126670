<template>
  <v-data-table
    :headers="headers"
    :items="rows"
    no-data-text="No check-ins found meeting criteria"
  >
    <template v-slot:item.purchase_id="{ item }">
      <router-link-id
        :id="item.purchase_id"
        name="purchase"
      />
    </template>
    <template v-slot:item.id="{ item }">
      <router-link-id
        :id="item.id"
        name="check-in"
      />
    </template>
    <template v-slot:item.created_at="{ item }">
      {{ item.created_at | formatDate }}
    </template>
    <template v-slot:item.units="{ item }">
      {{ item.total_counts | filterObject('total') }}
    </template>
    <template v-slot:item.boxes="{ item }">
      {{ item.transit_properties | filterObject('skids') }}
    </template>
    <template v-slot:body.append="{ header, item }">
      <tr>
        <td>
          Totals
        </td>
        <td colspan="4">
          {{ totalCheckIns }}
        </td>
        <td>
          {{ totalUnits }}
        </td>
        <td>
          {{ totalBoxes }}
        </td>
      </tr>
    </template>
  </v-data-table>
</template>
<script>
export default {
  name: 'CurrentCheckInTable',
  props: {
    rows: {
      type: Array,
      required: true
    }
  },
  data: function () {
    return {
      headers: [
        { text: 'Purchase ID', value: 'purchase_id' },
        { text: 'Check-In ID', value: 'id' },
        { text: 'Created At', value: 'created_at' },
        { text: 'Status', value: 'status' },
        { text: 'Yard', value: 'yard_name' },
        { text: 'Total Units', value: 'units' },
        { text: 'Boxes', value: 'boxes' }
      ]
    }
  },
  computed: {
    totalCheckIns () {
      return this.rows.length
    },
    totalUnits () {
      return this.rows.reduce((accu, ci) => {
        accu += ci.total_counts.total
        return accu
      }, 0)
    },
    totalBoxes () {
      return this.rows.reduce((accu, ci) => {
        accu += ci.transit_properties.skids
        return accu
      }, 0)
    }
  }
}
</script>
