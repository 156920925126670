var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.rows,"no-data-text":"No check-ins found meeting criteria"},scopedSlots:_vm._u([{key:"item.purchase_id",fn:function(ref){
var item = ref.item;
return [_c('router-link-id',{attrs:{"id":item.purchase_id,"name":"purchase"}})]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('router-link-id',{attrs:{"id":item.id,"name":"check-in"}})]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.created_at))+" ")]}},{key:"item.units",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("filterObject")(item.total_counts,'total'))+" ")]}},{key:"item.boxes",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("filterObject")(item.transit_properties,'skids'))+" ")]}},{key:"body.append",fn:function(ref){
var header = ref.header;
var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(" Totals ")]),_c('td',{attrs:{"colspan":"4"}},[_vm._v(" "+_vm._s(_vm.totalCheckIns)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.totalUnits)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.totalBoxes)+" ")])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }