<template>
  <material-card
    color="green"
    class="px-5 py-3"
  >
    <template v-slot:heading>
      <base-title>
        Check-Ins
        <info-tooltip>
          Displays check-ins created by the current user that are PRELIMINARY or OPEN
        </info-tooltip>
      </base-title>
    </template>
    <v-card-text>
      <current-check-in-table :rows="checkIns" />
    </v-card-text>
  </material-card>
</template>
<script>

import CurrentCheckInTable from './CurrentCheckInTable.vue'
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  name: 'CurrentCheckIns',
  components: { CurrentCheckInTable },
  data: function () {
    return {
      checkIns: []
    }
  },
  computed: {
    ...mapGetters([
      'currentUser'
    ])
  },
  methods: {
    async initialize () {
      let filters = {
        status__in: 'PR,OP',
        created_by__id: this.currentUser.id
      }
      await axios.get('/api/check_ins/?', { params: filters }).then(r => {
        this.checkIns = r.data.results
      })
    }
  },
  mounted () {
    this.initialize()
  }
}
</script>
